<template>
  <div class="page-detail">
    <van-nav-bar
      title="人员定位报警详情"
      :border="false"
      fixed
      left-arrow
      @click-left="navBack"
    >
    </van-nav-bar>
    <div class="detail-box">
      <van-form ref="baseForm" class="base-form">
        <div class="detail-item">
          <span class="label">报警围栏：</span>
          <span class="text">{{ info.alarmLocation }}</span>
        </div>
        <div v-if="info.applyType === 3" class="detail-item">
          <span class="label">车辆： </span>
          <span class="text">{{ info.alarmSubjectName }}</span>
        </div>
        <div v-else class="detail-item">
          <span class="label">人员： </span>
          <span class="text"
            >{{ info.alarmSubjectName }} {{ info.alarmSubjectPhone }}</span
          >
        </div>
        <div class="detail-item">
          <span class="label">类型： </span>
          <span class="text">{{ info.applyTypeName }}</span>
        </div>
        <div class="detail-item">
          <span class="label">报警时间： </span>
          <span class="text">{{ info.ctime }}</span>
        </div>
        <div class="detail-item">
          <span class="label">报警类型：</span>
          <span class="text">{{ info.alarmTypeName }}</span>
        </div>
        <div class="detail-item">
          <span class="label">报警状态：</span>
          <span class="text">{{ info.alarmStatusName }}</span>
        </div>
        <div class="detail-item">
          <span class="label">报警时长：</span>
          <span class="text">{{ info.alarmDurationLocal || "--" }}</span>
        </div>
        <div class="detail-item">
          <span class="label">处置状态：</span>
          <span class="text">{{ info.dealStatusName }}</span>
        </div>
        <div class="detail-item">
          <span class="label">响应时长：</span>
          <span class="text">{{ info.dealDurationLocal || "--" }}</span>
        </div>
        <div class="detail-item">
          <span class="label">报警信息：</span>
          <span class="text">{{ info.alarmContent }}</span>
        </div>
        <div class="detail-item">
          <span class="label">报警图片：</span>
          <span class="image">
            <tagpic ref="tagpic" :font-size="7"></tagpic>
          </span>
        </div>
      </van-form>
    </div>
    <div v-if="info.dealStatus" class="dispose-box">
      <div class="sub_title">处置反馈</div>
      <div class="dispose-content">
        {{ disposeInfo.dealContent }}
      </div>
      <div class="update-box">
        <span class="user">{{ disposeInfo.userName }}</span>
        <span class="time">{{ disposeInfo.ctime }}</span>
      </div>
    </div>
    <van-action-sheet v-model="actionsShow" :closeable="false">
      <div class="title-box">
        处置反馈
        <span @click="disposeSubmit()">提交</span>
      </div>
      <div class="content">
        <van-field
          ref="disposeContent"
          v-model="dealContent"
          rows="3"
          maxlength="500"
          type="textarea"
          placeholder="请输入处置反馈"
        />
      </div>
    </van-action-sheet>
    <div v-if="info.dealStatus === 0 && btnAuth.deal" class="fixed">
      <van-button class="btn-primary" @click="dispose()">处置反馈</van-button>
    </div>
  </div>
</template>

<script>
import { getInfoLocationAlarm, postLocationAlarmDeal } from "@/api/alarm";
import { mapState } from "vuex";

export default {
  name: "PositionAlarmDetail",
  components: {
    tagpic: () => import("@ty-tagpic")
  },
  data() {
    return {
      actionsShow: false,
      info: {},
      disposeInfo: {},
      dealContent: ""
    };
  },
  computed: {
    ...mapState({
      btnAuth: state => state.menu.btnAuth
    }),
    id() {
      return this.$route.params.id;
    }
  },
  watch: {},
  // 生命周期 - 创建完成（可以访问当前this实例）
  created() {
    this.getInfoLocationAlarm();
  },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
  methods: {
    formatDuring(mss) {
      if (mss === null) {
        return "--";
      }
      var hours = parseInt(mss / (1000 * 60 * 60));
      var minutes = parseInt((mss % (1000 * 60 * 60)) / (1000 * 60));
      var seconds = (mss % (1000 * 60)) / 1000;
      hours = hours ? `${hours}小时` : "";
      minutes = minutes ? `${minutes}分钟` : "";
      seconds = parseInt(seconds) ? `${parseInt(seconds)}秒` : "";
      return hours + minutes + seconds || "--";
    },
    getInfoLocationAlarm() {
      getInfoLocationAlarm(this.id).then(res => {
        res.alarmDurationLocal = this.formatDuring(res.alarmDuration);
        res.dealDurationLocal = this.formatDuring(res.dealDuration);
        this.info = res;
        this.disposeInfo = (res.recordDeals && res.recordDeals[0]) || {};
        let personsData = res.recordSubjects?.map(v => {
          let arr = v.geo.split(",");
          return {
            jd: arr[0],
            wd: arr[1],
            name: v.alarmSubjectName || res.alarmSubjectName
          };
        });
        let imgData = res.geoDetail
          ? JSON.parse(decodeURIComponent(res.geoDetail))
          : this.$refs.tagpic.getDefaultImgData();
        this.$refs.tagpic.loadData(personsData, imgData);
      });
    },
    navBack() {
      this.$router.back();
    },
    disposeSubmit() {
      let data = {
        recordId: this.id,
        alarmConfigId: this.info.alarmConfigId,
        dealContent: this.dealContent
      };
      this.$dialog
        .confirm({
          title: "",
          message: "确认提交处置反馈信息？",
          confirmButtonText: "确认",
          cancelButtonText: "取消"
        })
        .then(() => {
          postLocationAlarmDeal(data).then(res => {
            this.actionsShow = false;
            this.getInfoLocationAlarm();
          });
        })
        .catch(_ => {});
    },
    async dispose() {
      this.actionsShow = true;
      await this.$nextTick();
      this.$refs.disposeContent.focus();
    }
  }
};
</script>

<style lang="scss" scoped>
.fixed {
  position: fixed;
  display: flex;
  width: 100%;
  bottom: 16px;
  z-index: 100;
  box-sizing: border-box;
  padding: 0px 16px;

  .van-button {
    flex-grow: 1;

    &:not(:last-child) {
      margin-right: 13px;
    }
  }
}

.page-detail {
  box-sizing: border-box;
  padding: 60px 0 40px;
  height: 100%;
  overflow: auto;
}

.detail-box {
  box-sizing: border-box;
  padding: 12px 16px;
  .detail-item {
    display: flex;
    align-items: flex-start;
    padding: 0 0 8px;
    line-height: 20px;
    font-size: 14px;
    font-weight: 400;
    .label {
      width: 90px;
      color: #8c8f97;
    }
    .text {
      flex: 1;
      word-break: break-word;
      color: #2e2e4d;
    }
    .image {
      position: relative;
      width: 240px;
      overflow: hidden;
      img {
        width: 100%;
        height: auto;
      }
    }
  }
}

.dispose-box {
  border-top: 8px solid #eceef2;

  .sub_title {
    padding: 0 16px;
    height: 44px;
    font-size: 14px;
    font-weight: bolder;
    color: #2e2e4d;
    line-height: 44px;
    border-bottom: 1px solid #c7c9d0;
  }

  .dispose-content {
    padding: 12px 16px;
    font-size: 14px;
    font-weight: 400;
    color: #2e2e4d;
    line-height: 22px;
  }

  .update-box {
    display: flex;
    padding: 0 16px;
    justify-content: space-between;
    align-items: center;

    span {
      font-size: 12px;
      font-weight: 400;
      color: #8c8f97;
      line-height: 17px;
    }
  }
}

.van-action-sheet__content {
  .title-box {
    position: relative;
    text-align: center;
    padding: 15px 0 12px;
    height: 22px;
    font-size: 16px;
    font-weight: 500;
    color: #2e2e4d;
    line-height: 22px;

    span {
      position: absolute;
      right: 16px;
      top: 15px;
      font-size: 14px;
      font-weight: 400;
      color: #1676ff;
      line-height: 22px;
    }
  }

  .van-field {
    width: calc(100% - 32px);
    box-sizing: border-box;
    margin: 0 16px 16px;
    padding: 8px 10px;
    border: 1px solid #e1e3e8;
  }
}
</style>
